import React, { createContext, useState } from 'react';

const SearchContext = createContext();

const SearchProvider = ({ children }) => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [facets, setFacets] = useState({});
  const [selectedFacets, setSelectedFacets] = useState({});
  const [totalHits, setTotalHits] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const contextValue = {
    query, setQuery,
    suggestions, setSuggestions,
    searchResults, setSearchResults,
    facets, setFacets,
    selectedFacets, setSelectedFacets,
    totalHits, setTotalHits,
    totalPages, setTotalPages,
    currentPage, setCurrentPage,
    isLoading, setIsLoading,
    error, setError
  };

  return (
    <SearchContext.Provider value={contextValue}>
      {children}
    </SearchContext.Provider>
  );
};

export { SearchContext, SearchProvider };
