import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const SearchResultComponent = ({ searchResults, handleSuggestionClick }) => {
  const renderClassification = (parents) => {
    if (!parents || parents.length === 0) return 'No classification available';
    const reversedParents = parents.slice().reverse();

    if (reversedParents.length <= 5) {
      return reversedParents.map(parent => parent.taxon_name).join(' > ');
    }

    const firstTwo = reversedParents.slice(0, 2).map(parent => parent.taxon_name);
    const middle = reversedParents[Math.floor(reversedParents.length / 2)].taxon_name;
    const lastTwo = reversedParents.slice(-2).map(parent => parent.taxon_name);

    return `${firstTwo.join(' > ')} > +${reversedParents.length - 5} > ${middle} > ${lastTwo.join(' > ')}`;
  };

  return (
    <Row className="search-results-list">
      {searchResults.map((result) => (
        <Col md={12} key={result.taxon.taxon_id} className="mb-4">
          <Card onClick={() => handleSuggestionClick(result.taxon.taxon_id)} className="h-100">
            <Card.Header className="d-flex justify-content-between">
              <Card.Title className="mb-0 taxon_name">{result.taxon.scientific_name}</Card.Title>
              <Card.Title className="mb-0 taxon_rank">{result.taxon.rank_name}</Card.Title>
            </Card.Header>
            <Card.Body>
              <div className='d-flex justify-content-between'>
                {result.taxon.taxon_genus && (
                  <Card.Text className='genus'>Genus: {result.taxon.taxon_genus}</Card.Text>
                )}
                {result.taxon.taxon_family && (
                  <Card.Text className='family'>Family: {result.taxon.taxon_family}</Card.Text>
                )}
              </div>
              <Card.Text className='classification'>Classification: {renderClassification(result.parent)}</Card.Text>
              {result.taxon.papers && result.taxon.papers.length > 0 && (
                <div className='papers'>
                  <h6>Papers:</h6>
                  <ul>
                    {result.taxon.papers.map((paper, index) => (
                      <li key={index}>
                        <strong>{paper.paper_author}</strong>: {paper.paper_title}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </Card.Body>
            <Card.Footer className="text-muted">valid</Card.Footer>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default SearchResultComponent;
