import React from 'react';
import { Pagination } from 'react-bootstrap';

const PaginationComponent= ({ currentPage, totalPages, handlePageChange }) => {
    const renderPageNumbers = () => {
      const pageNumbers = [];
      const maxPagesToShow = 5;
  
      if (totalPages <= maxPagesToShow + 2) {
        // If total pages are less than maxPagesToShow + 2, show all pages
        for (let i = 1; i <= totalPages; i++) {
          pageNumbers.push(
            <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
              {i}
            </Pagination.Item>
          );
        }
      } else {
        if (currentPage <= 3) {
          // Show first maxPagesToShow pages
          for (let i = 1; i <= maxPagesToShow; i++) {
            pageNumbers.push(
              <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
                {i}
              </Pagination.Item>
            );
          }
          pageNumbers.push(<Pagination.Ellipsis key="ellipsis1" />);
          pageNumbers.push(
            <Pagination.Item key={totalPages} onClick={() => handlePageChange(totalPages)}>
              {totalPages}
            </Pagination.Item>
          );
        } else if (currentPage >= totalPages - 2) {
          // Show last maxPagesToShow pages
          pageNumbers.push(
            <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
              1
            </Pagination.Item>
          );
          pageNumbers.push(<Pagination.Ellipsis key="ellipsis1" />);
          for (let i = totalPages - maxPagesToShow + 1; i <= totalPages; i++) {
            pageNumbers.push(
              <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
                {i}
              </Pagination.Item>
            );
          }
        } else {
          // Show pages around the currentPage
          pageNumbers.push(
            <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
              1
            </Pagination.Item>
          );
          pageNumbers.push(<Pagination.Ellipsis key="ellipsis1" />);
          for (let i = currentPage - 2; i <= currentPage + 2; i++) {
            pageNumbers.push(
              <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
                {i}
              </Pagination.Item>
            );
          }
          pageNumbers.push(<Pagination.Ellipsis key="ellipsis2" />);
          pageNumbers.push(
            <Pagination.Item key={totalPages} onClick={() => handlePageChange(totalPages)}>
              {totalPages}
            </Pagination.Item>
          );
        }
      }
  
      return pageNumbers;
    };
  
    return (
      <Pagination className="mt-3">
        <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
        {renderPageNumbers()}
        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
        <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
      </Pagination>
    );
  };
  

export default PaginationComponent;
