/** @format */

import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SearchContext } from "./SearchContext";
import TaxonomyTree from "./components/TaxonTreeComponent";
import axios from "axios";
import { Spinner, Alert } from "react-bootstrap";
import DonutChartComponent from "./components/DonutChartComponent";
import SpeciesMapComponent from "./components/SpeciesMapComponent";
const apiUrl = process.env.REACT_APP_API_URL;

const TaxonDetailPage = () => {
  const { id } = useParams();
  const { searchResults, setSuggestions } = useContext(SearchContext);

  // const taxonData = searchResults.find(result => result.taxon.taxon_id === id);
  const [taxonData, setTaxonData] = useState(
    () => searchResults.find((result) => result.taxon.taxon_id === id) || null
  );
  const [isLoading, setIsLoading] = useState(!taxonData);
  const [error, setError] = useState(null);

  const fetchTaxonDetails = async (taxonId) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${apiUrl}/taxon/${taxonId}`
      );
      setTaxonData(response.data);
    } catch (err) {
      setError("Error fetching taxon details");
      console.error("Error fetching taxon details:", err);
    } finally {
      setIsLoading(false);
    }
  };
  const renderTaxonDetailItem = (title, value) => {
    return (
      <div className="row taxon_info">
        <div className="col-md-4">
          <h5>{title}</h5>
        </div>
        <div className="col-md-8">
          <h5>{value}</h5>
        </div>
      </div>
    );
  };


  useEffect(() => {
    setSuggestions([]); // Clear suggestions when component mounts
    const existingTaxonData = searchResults.find(result => result.taxon.taxon_id === id);
    if (existingTaxonData) {
      setTaxonData(existingTaxonData);
      setIsLoading(false);
    } else {
      setTaxonData(null); // Clear previous taxon data
      fetchTaxonDetails(id); // Fetch new taxon details based on id
    }
  }, [id, setSuggestions, searchResults]);
  
  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  if (!taxonData) {
    return <div>No data found for this taxon.</div>;
  }

  return (
    <div className="container-fluid,taxon_detail">
      <div className="row">
        <div className="col-md-3">
          <TaxonomyTree taxonData={taxonData} />
        </div>
        <div className="col-md-6">



          <div className="row">
            <h2> {taxonData.taxon.scientific_name}</h2>
          </div>
          <div>
            {taxonData.taxon.scientific_name ? (
              renderTaxonDetailItem(
                "Scientific Name:",
                taxonData.taxon.scientific_name
              )
            ) : (
              <p>No taxon name available</p>
            )}
          </div>
          <div>
            {taxonData.taxon.Taxon_Status ? (
              renderTaxonDetailItem("Status:", taxonData.taxon.Taxon_Status)
            ) : (
              <p>No taxon Status available</p>
            )}
          </div>
          <div>
            {taxonData.taxon.rank_name ? (
              renderTaxonDetailItem("Rank:", taxonData.taxon.rank_name)
            ) : (
              <p>No taxon Rank available</p>
            )}
          </div>

          <div>
            {taxonData.taxon.taxon_family ? (
              renderTaxonDetailItem("Family:", taxonData.taxon.taxon_family)
            ) : (
              <p>No taxon Family available</p>
            )}
          </div>
          <div>
            {taxonData.taxon.taxon_genus
              ? renderTaxonDetailItem("Genus:", taxonData.taxon.taxon_genus)
              : renderTaxonDetailItem(
                  "Genus:",
                  <p>!!!No taxon genus available</p>
                )}
          </div>
          <div className="row">

          {taxonData.taxon.Areas && taxonData.taxon.Areas.length > 0 && (
          <SpeciesMapComponent data={taxonData.taxon.Areas} /> // Pass the presences data to SpeciesMap
          )}
          </div>
          <div className="row">
            {taxonData.statistics && taxonData.statistics.length > 0 && (
              <DonutChartComponent data={taxonData.statistics} /> // Pass the statistics data to DonutChart
            )}
          </div>
          <div>
            {taxonData.taxon.authors.length > 0
              ? renderTaxonDetailItem("Author:", taxonData.taxon.authors)
              : renderTaxonDetailItem(
                  "Author:",
                  <p>!!!No taxon Author available</p>
                )}
          </div>
          <div>
            {taxonData.taxon.Papers
              ? renderTaxonDetailItem("Papers:", taxonData.taxon.Papers)
              : renderTaxonDetailItem(
                  "Papers:",
                  <p>!!!No taxon Papers available</p>
                )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaxonDetailPage;
