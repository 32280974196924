// src/App.js
import React  from 'react';
import SearchPage from './SearchPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TaxonDetailPage from './TaxonDetailPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import HomePage from './HomePage';
import { SearchProvider } from './SearchContext';
import Header from './Header';
import Footer from './Footer';
function App() {
  return (

    <div className="container-fluid">
    <Header />
    <SearchProvider>
    <Router>
      <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/search" element={<SearchPage />} />
      <Route path="/taxon/:id" element={<TaxonDetailPage />} />
      </Routes>
    </Router>
    </SearchProvider>
    <Footer />
    </div>


  );
}

export default App;
