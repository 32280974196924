// src/DonutChart.js
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const DonutChartComponent = ({ data }) => {
  const chartOptions = {
    chart: {
      type: 'pie'
    },
    title: {
      text: 'Taxon Sub-Ranks and Total Number of Species'
    },
    plotOptions: {
      pie: {
        innerSize: '50%',
        depth: 45,
        dataLabels: {
          enabled: true,
          format: '{point.name}: {point.y}'
        }
      }
    },
    series: [
      {
        name: 'Species',
        data: data.map(item => ({
          name: item.rank,
          y: item.count
        }))
      }
    ]
  };

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default DonutChartComponent;
