import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const TaxonomyTree = ({ taxonData }) => {
  const navigate = useNavigate();

  if (!taxonData) return null;

  const parentNodes = taxonData.parent.map(node => (
    <ListGroup.Item 
    key={node.taxon_id}
    onClick={() => handleTreeItemClick(node.taxon_id)}

    >({node.rank}):{node.taxon_name}</ListGroup.Item>
  )).reverse();

  const handleTreeItemClick = (id) => {
    navigate(`/taxon/${id}`);
  };

  const childrenNodes = taxonData.children.length > 0 ? (
    <>
      <h3>Immediate Children</h3>
      <ListGroup>
        {taxonData.children.map(node => (
          <ListGroup.Item 
          key={node.taxon_id}
          onClick={() => handleTreeItemClick(node.taxon_id)}

          >{node.rank}:{node.taxon_name}</ListGroup.Item>
        ))}
      </ListGroup>
    </>
  ) : null;

  return (
    <div style={{ padding: '20px', border: '1px solid #ccc' }}>
      <h2>Taxonomy Tree</h2>
      <h3>Parents</h3>
      <ListGroup>{parentNodes}</ListGroup>
      {childrenNodes}
    </div>
  );
};

export default TaxonomyTree;
