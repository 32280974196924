/** @format */

import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import {
  ListGroup,
  InputGroup,
  FormControl,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { SearchContext } from "./SearchContext";
import "./SearchBar.css"; // Optional: for styling
import SearchResultComponent from "./components/SearchResultComponent";
import FacetComponent from "./components/FacetsComponent";
import PaginationComponent from "./components/PaginationComponent";
import SelectedFacetsComponent from "./components/SelectedFacetsComponent";
import ErrorBoundary from "./ErrorBoundary";
const apiUrl = process.env.REACT_APP_API_URL;

const SearchPage = () => {
  const {
    query,
    setQuery,
    suggestions,
    setSuggestions,
    searchResults,
    setSearchResults,
    facets,
    setFacets,
    setSelectedFacets,
    totalHits,
    setTotalHits,
    totalPages,
    setTotalPages,
    currentPage,
    setCurrentPage,
    isLoading,
    setIsLoading,
    error,
    setError,
  } = useContext(SearchContext);

  const hasSearchResults = searchResults && searchResults.length > 0;
  const [activeFacets, setActiveFacets] = useState(() => {
    const savedFacets = localStorage.getItem("activeFacets");
    return savedFacets ? JSON.parse(savedFacets) : {};
  });

  const navigate = useNavigate();
  const suggestionsRef = useRef(null);

  useEffect(() => {
    localStorage.setItem("activeFacets", JSON.stringify(activeFacets));
  }, [activeFacets]);

  const handleSuggestionClick = (id) => {
    navigate(`/taxon/${id}`);
  };

  const handleSearch = async (page = 1) => {
    if (query.length > 1) {
      setIsLoading(true);
      setError(null);
      setSuggestions([]); // Reset suggestions to empty
      setSelectedFacets({}); // Reset facets to empty
      setActiveFacets({});
      try {
        const response = await axios.post(
          `${apiUrl}/search/faceted_search`,
          { query: query, page, size: 10 }
        );
        setSearchResults(response.data.search_results);
        setFacets(response.data.facets);
        setTotalHits(response.data.total_hits);
        setTotalPages(response.data.total_pages);
        setCurrentPage(page);
      } catch (error) {
        setError("Error fetching search results");
        console.error("Error fetching search results:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleFacetClick = async (facetKey, facetValue) => {
    setIsLoading(true);
    setError(null);

    const updatedActiveFacets = { ...activeFacets };
    if (updatedActiveFacets[facetKey]) {
      if (updatedActiveFacets[facetKey].includes(facetValue)) {
        updatedActiveFacets[facetKey] = updatedActiveFacets[facetKey].filter(
          (value) => value !== facetValue
        );
        if (updatedActiveFacets[facetKey].length === 0) {
          delete updatedActiveFacets[facetKey];
        }
      } else {
        updatedActiveFacets[facetKey].push(facetValue);
      }
    } else {
      updatedActiveFacets[facetKey] = [facetValue];
    }

    setActiveFacets(updatedActiveFacets);

    // Make the API call with the updated active facets
    try {
      const response = await axios.post(
        `${apiUrl}/search/faceted_search`,
        {
          query: query,
          page: 1,
          size: 10,
          facets: updatedActiveFacets,
        }
      );
      setSearchResults(response.data.search_results);
      setFacets(response.data.facets);
      setTotalHits(response.data.total_hits);
      setTotalPages(response.data.total_pages);
      setCurrentPage(1);
    } catch (error) {
      setError("Error fetching search results");
      console.error("Error fetching search results:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFacetRemove = async (facetKey, facetValue) => {
    const updatedActiveFacets = { ...activeFacets };
    if (updatedActiveFacets[facetKey]) {
      updatedActiveFacets[facetKey] = updatedActiveFacets[facetKey].filter(
        (value) => value !== facetValue
      );
      if (updatedActiveFacets[facetKey].length === 0) {
        delete updatedActiveFacets[facetKey];
      }
    }

    setActiveFacets(updatedActiveFacets);

    // Make the API call with the updated active facets
    try {
      const response = await axios.post(
        `${apiUrl}/search/faceted_search`,
        {
          query: query,
          page: 1,
          size: 10,
          facets: updatedActiveFacets,
        }
      );
      setSearchResults(response.data.search_results);
      setFacets(response.data.facets);
      setTotalHits(response.data.total_hits);
      setTotalPages(response.data.total_pages);
      setCurrentPage(1);
    } catch (error) {
      setError("Error fetching search results");
      console.error("Error fetching search results:", error);
    }
  };

  const handlePageChange = (pageNumber) => {
    handleSearch(pageNumber);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(1);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target)
      ) {
        setSuggestions([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setSuggestions]);

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (query.length > 1) {
        setIsLoading(true);
        setError(null);
        try {
          const response = await axios.get(
            `${apiUrl}/search/autocomplete?query=${query}`
          );
          setSuggestions(response.data);
        } catch (error) {
          setError("Error fetching autocomplete suggestions");
          console.error("Error fetching autocomplete suggestions:", error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setSuggestions([]);
      }
    };

    fetchSuggestions();

    // Clear suggestions when the component unmounts
    return () => setSuggestions([]);
  }, [query, setIsLoading, setError, setSuggestions]);

  return (
    <div className="search-page">
      <Container>
        <Row>
          <Col>
            <div className="search-bar">
              <InputGroup>
                <FormControl
                  type="text"
                  value={query}
                  onKeyUp={handleKeyPress}
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder="Search..."
                  className="search-input"
                />
                <InputGroup.Text
                  onClick={() => handleSearch(1)}
                  style={{ cursor: "pointer" }}
                >
                  <i className="bi bi-search"></i>
                </InputGroup.Text>
              </InputGroup>
              <p className="text-muted">Found: {totalHits} records</p>
              {suggestions.length > 0 && (
                <ListGroup className="suggestions-list" ref={suggestionsRef}>
                  {suggestions.map((suggestion) => (
                    <ListGroup.Item
                      key={suggestion.taxon_id}
                      onClick={() => handleSuggestionClick(suggestion.taxon_id)}
                    >
                      {suggestion.taxon_name}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
              {isLoading && <div className="loading">Loading...</div>}
              {error && <div className="error">{error}</div>}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <SelectedFacetsComponent
              activeFacets={activeFacets}
              handleFacetRemove={handleFacetRemove}
            />
          </Col>
        </Row>
      </Container>
      <div className="container-fluid">
        <Row>
          {hasSearchResults ? (
            <>
              <Col md={3}>
                <ErrorBoundary>
                  {facets &&
                    Object.keys(facets).some(
                      (key) => facets[key]?.length > 0
                    ) && (
                      <FacetComponent
                        facets={facets}
                        handleFacetClick={handleFacetClick}
                        activeFacets={activeFacets}
                        setActiveFacets={setActiveFacets}
                      />
                    )}
                </ErrorBoundary>
              </Col>
              <Col md={9}>
                <ErrorBoundary>
                  {searchResults && searchResults.length > 0 && (
                    <SearchResultComponent
                      searchResults={searchResults}
                      handleSuggestionClick={handleSuggestionClick}
                    />
                  )}
                </ErrorBoundary>
                <ErrorBoundary>
                  {totalPages > 1 && (
                    <PaginationComponent
                      currentPage={currentPage}
                      totalPages={totalPages}
                      handlePageChange={handlePageChange}
                    />
                  )}
                </ErrorBoundary>
              </Col>
            </>
          ) : (
            !isLoading 
          )}
        </Row>
      </div>
    </div>
  );
};

export default SearchPage;
