// components/SelectedFacets.js
import React from "react";
import { Button, Badge } from "react-bootstrap";

const SelectedFacetsComponent = ({ activeFacets, handleFacetRemove }) => {
  const renderFacets = () => {
    return Object.keys(activeFacets).map((key) => (
      <div key={key} className="selected-facet">
        {activeFacets[key].map((value) => (
          <Badge pill bg="secondary" key={value} className="facet-badge">
            {key}: {value}
            <Button
              variant="link"
              size="sm"
              onClick={() => handleFacetRemove(key, value)}
            >
              ✕
            </Button>
          </Badge>
        ))}
      </div>
    ));
  };

  return (
    <div className="selected-facets">
      {Object.keys(activeFacets).length > 0 ? (
        renderFacets()
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default SelectedFacetsComponent;
