import React from 'react';
import { Container } from 'react-bootstrap';
import SliderComponent from './components/SliderComponent';
import ContentCardComponent from './components/ContentCardComponent';

const HomePage = () => {
  return (
    <div>
      <SliderComponent />
      <Container className="mt-4">
        <ContentCardComponent />
      </Container>
    </div>
  );
};

export default HomePage;
