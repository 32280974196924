// src/SpeciesMap.js
import React, { useMemo } from 'react';
import { MapContainer, TileLayer, GeoJSON, Tooltip, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import countriesData from '../countries.geo.json'; // Ensure this path is correct
import CountryNameMapping from './CountryNameMapping'; // Import the mapping

const SpeciesMapComponent = ({ data }) => {
  // Memoize the presenceMap to optimize performance
  const presenceMap = useMemo(() => {
    if (!data) return {};

    return data.reduce((acc, item) => {
      const mappedName = CountryNameMapping[item.country];
      console.log("Original name:", item.country);
      console.log("Presence:", item.presence);
      console.log("Mapped name:", mappedName);
      if (mappedName) { // Only include if mappedName is not null
        acc[mappedName] = Number(item.presence); // Ensure presence is a number
      }
      return acc;
    }, {});
  }, [data]);

  // Function to determine color based on presence value
  const getColor = (presence) => {
    switch (presence) {
      case 0:
        return 'red';
      case 1:
        return 'gray';
      case 2:
        return 'green';
      default:
        return 'blue'; // Default color if presence value is undefined
    }
  };

  // Style each country feature
  const style = (feature) => {
    const isoCode = feature.properties.adm0_a3; // Correct property access
    const presence = presenceMap[isoCode] !== undefined ? presenceMap[isoCode] : null;
    return {
      fillColor: presence !== null ? getColor(presence) : '#FFFFFF', // White if no data
      weight: 1,
      opacity: 1,
      color: 'black',
      dashArray: '3',
      fillOpacity: 0.7,
    };
  };

  // Bind popups and tooltips to each country
  const onEachFeature = (feature, layer) => {
    const isoCode = feature.properties.adm0_a3; // Correct property access
    const countryName = feature.properties.name; // Use 'name' property
    const presence = presenceMap[isoCode];
    console.log("Country ISO:", isoCode, "Presence:", presence);

    if (presence !== undefined) {
      layer.bindPopup(`<strong>${countryName}</strong><br/>Presence: ${presence}`);
      layer.bindTooltip(`${countryName}: Presence ${presence}`);
    } else {
      layer.bindPopup(`<strong>${countryName}</strong><br/>No Data`);
      layer.bindTooltip(`${countryName}: No Data`);
    }
  };

  // Conditional Rendering: Show loading indicator if data is not available
  if (!data) {
    return <div>Loading map data...</div>;
  }

  return (
    <MapContainer center={[20, 0]} zoom={2} style={{ height: '500px', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <GeoJSON data={countriesData} style={style} onEachFeature={onEachFeature} />
    </MapContainer>
  );
};

export default SpeciesMapComponent;
