import React, { useState } from 'react';
import { ListGroup, Modal, Button, Row, Col } from 'react-bootstrap';

const FacetComponent = ({ facets, activeFacets, setActiveFacets, handleFacetClick }) => {
  const [showModal, setShowModal] = useState(false);
  const [currentFacetKey, setCurrentFacetKey] = useState('');
  const [remainingFacets, setRemainingFacets] = useState([]);

  const facetDisplayNames = {
    'Areas.country': 'Country',
    'rank_name': 'Rank',
    'taxon_genus': 'Genus',
    'taxon_family': 'Family',
  };

  const handleMoreClick = (facetKey, remainingFacets) => {
    setCurrentFacetKey(facetKey);
    setRemainingFacets(remainingFacets);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const getDisplayName = (facetKey) => {
    return facetDisplayNames[facetKey] || facetKey;
  };

  const handleFacetItemClick = (facetKey, facetValue) => {
    const updatedActiveFacets = { ...activeFacets };
    if (updatedActiveFacets[facetKey]) {
      if (updatedActiveFacets[facetKey].includes(facetValue)) {
        updatedActiveFacets[facetKey] = updatedActiveFacets[facetKey].filter(value => value !== facetValue);
        if (updatedActiveFacets[facetKey].length === 0) {
          delete updatedActiveFacets[facetKey];
        }
      } else {
        updatedActiveFacets[facetKey].push(facetValue);
      }
    } else {
      updatedActiveFacets[facetKey] = [facetValue];
    }
    setActiveFacets(updatedActiveFacets);
    handleFacetClick(facetKey, facetValue);
  };

  const isActive = (facetKey, facetValue) => {
    return activeFacets[facetKey] && activeFacets[facetKey].includes(facetValue);
  };

  return (
    <div className="facets">
      <h3>Filters</h3>
      {Object.keys(facets).map((facetKey) => (
        facets[facetKey].length > 0 && (
          <div key={facetKey}>
            <h4>{getDisplayName(facetKey)}</h4>
            <ListGroup>
              {facets[facetKey].slice(0, 5).map((facet, index) => (
                <ListGroup.Item
                  key={index}
                  active={isActive(facetKey, facet.key)}
                  onClick={() => handleFacetItemClick(facetKey, facet.key)}
                >
                  {facet.key} ({facet.doc_count})
                </ListGroup.Item>
              ))}
              {facets[facetKey].length > 5 && (
                <ListGroup.Item action onClick={() => handleMoreClick(facetKey, facets[facetKey].slice(5))}>
                  More...
                </ListGroup.Item>
              )}
            </ListGroup>
          </div>
        )
      ))}

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{getDisplayName(currentFacetKey)} - More Facets</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {remainingFacets.map((facet, index) => (
              <Col md={6} key={index}>
                <ListGroup.Item
                  active={isActive(currentFacetKey, facet.key)}
                  onClick={() => {
                    handleFacetItemClick(currentFacetKey, facet.key);
                    handleCloseModal();
                  }}
                >
                  {facet.key} ({facet.doc_count})
                </ListGroup.Item>
              </Col>
            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FacetComponent;
