// src/countryCodeMapping.js
const countryCodeMapping = {
  "Russia Northwest": "RUS",
  "Monaco": "MCO",
  "Andorra": "AND",
  "Channel Is.": "CHI", // Verify the correct ISO code or handle specially
  "Gibraltar": "GIB",
  "Albania": "ALB",
  "Moldova, Republic of": "MDA",
  "Sweden": "SWE",
  "Slovenia": "SVN",
  "Britain I.": "GBR",
  "Macedonia": "MKD",
  "Northern Ireland": "GBR",
  "Malta": "MLT",
  "Austria": "AUT",
  "North Aegean Is.": "GRC",
  "Slovakia": "SVK",
  "The Netherlands": "NLD",
  "Cyclades Is.": "GRC",
  "Bosnia and Herzegovina": "BIH",
  "Norwegian mainland": "NOR",
  "San Marino": "SMR",
  "Dodecanese Is.": "GRC",
  "Svalbard & Jan Mayen": "SJM",
  "Belgium": "BEL",
  "Greek mainland": "GRC",
  "Poland": "POL",
  "European Turkey": "TUR",
  "Bulgaria": "BGR",
  "Azores": "PRT",
  "Crete": "GRC",
  "Ukraine": "UKR",
  "Belarus": "BLR",
  "Madeira": "PRT",
  "Vatican City": "VAT",
  "Croatia": "HRV",
  "Switzerland": "CHE",
  "Hungary": "HUN",
  "Portuguese mainland": "PRT",
  "Cyprus": "CYP",
  "Yugoslavia": "SRB", // Map to Serbia or appropriate current country
  "Ireland": "IRL",
  "Selvagens Is.": "PRT",
  "Czech Republic": "CZE",
  "Afro-tropical region": null, // Handle separately or exclude
  "Iceland": "ISL",
  "Germany": "DEU",
  "Romania": "ROU",
  "Italian mainland": "ITA",
  "Australian region": "AUS",
  "Danish mainland": "DNK",
  "Sardinia": "ITA",
  "Franz Josef Land": "RUS",
  "East Palaearctic": null, // Handle separately or exclude
  "Sicily": "ITA",
  "Kaliningrad Region": "RUS",
  "North Africa": null, // Handle separately or exclude
  "Faroe Is.": "FRO",
  "Liechtenstein": "LIE",
  "Novaya Zemlya": "RUS",
  "Estonia": "EST",
  "Lithuania": "LTU",
  "Russia Central": "RUS",
  "Nearctic region": null, // Handle separately or exclude
  "Balearic Is.": "ESP",
  "Luxembourg": "LUX",
  "Canary Is.": "ESP",
  "Neotropical region": null, // Handle separately or exclude
  "Russia East": "RUS",
  "Spanish mainland": "ESP",
  "Latvia": "LVA",
  "Finland": "FIN",
  "Russia North": "RUS",
  "Near East": null, // Handle separately or exclude
  "Corsica": "FRA",
  "Russia South": "RUS",
  "French mainland": "FRA",
  "Oriental region": null // Handle separately or exclude
};

export default countryCodeMapping;
