import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

const ContentCardComponent = () => {
  const cardData = [
    { title: "Card 1", text: "This is card 1", img: "https://via.placeholder.com/150" },
    { title: "Card 2", text: "This is card 2", img: "https://via.placeholder.com/150" },
    { title: "Card 3", text: "This is card 3", img: "https://via.placeholder.com/150" },
    { title: "Card 4", text: "This is card 4", img: "https://via.placeholder.com/150" },
  ];

  return (
    <Row>
      {cardData.map((card, index) => (
        <Col key={index} md={3} className="mb-4">
          <Card>
            <Card.Img variant="top" src={card.img} />
            <Card.Body>
              <Card.Title>{card.title}</Card.Title>
              <Card.Text>{card.text}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default ContentCardComponent;
